export default {
    data() {
        return {
            variantAttribute: false,
        }
    },
    methods: {
        async setVariantAttribute(attr, header, modulecode) {
            console.log('')
            console.log('============')
            console.log('Form:', header.form)
            console.log('Budget version:', attr ? 'active' : 'NOT active')
            if (!attr) {
                this.variantAttribute = false;
                return;
            }
            console.log('usrId:', header.user_name)
            console.log('modulecode:', modulecode)
            const aggrState = header.mode === 'gkkp' || await this.getAggrState(header)
            console.log('Aggriment State:', aggrState)    
            let accsState = false;
            if (aggrState) {
                accsState = await this.getAccessData(header.user_name, modulecode)
                console.log('Access State:', accsState)      
            }
           
            this.variantAttribute = aggrState && accsState
            console.log('Редактирование', this.variantAttribute ? 'разрешено' : 'запрещено')
        },

        async getAggrState(header) {
            try {
                const response = await fetch('/api-py/get-budget-request-aggriment-state/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(header)
                });
                    if (response.status === 200) {
                        const result = await response.json();
                        return result
                    }
            } catch (error) {
                this.makeToast('danger', 'Ошибка получения уровня доступа', error.toString());
            }
            return false
        },

        async getAccessData(usrId, modulecode) { // получение разрешенных доступов
            if (!usrId) return false;
            try {
                const response = await fetch(`api/um/module/link?user=${usrId}&modulecode=${modulecode}`)
                if (response.status === 404) {
                    return false;
                }
                if (response.status === 200) {
                    const values = await response.json();
                    if (values.accessLevel && (values.accessLevel === 2 || values.accessLevel === 3)) return true;
                } else {
                    this.makeToast('danger', 'Ошибка загрузки уровней доступа', `${response.status} - ${response.statusText}`);
                }
            } catch (error) {
                if (error.toString() === 'ReferenceError: response is not defined') return false;
                this.makeToast('danger', 'Ошибка загрузки уровней доступа', error.toString());
            }
            return false;
        }
    }
}