




































































































































import {Component, Prop} from 'vue-property-decorator';
import BfCopyDataMixin from '../mixins/bf-copy-data-mixin';

interface IDoubleOptions {
    text: string | Function,
    value: string
}

@Component({
    name: 'c-budget-forms-copy-data',
})

export default class CBudgetFormsCopyData extends BfCopyDataMixin {
    // ======================= PROPS section
    @Prop({
        required: true,
        default: false
    })
    private budgetForm!: any[];

    @Prop({
        required: false,
        default: false
    })
    private isLoad!: boolean;

    @Prop({
        required: false,
        default: 1
    })
    private total!: number;

    // ======================= Variables section
    private get doubleOptions(): IDoubleOptions[] {
        return [
                    {
                        text: this.formCodeText,
                        value: this.header && this.header.form ? this.header.form : ''
                    },
                    {
                        text: this.getDuplicateText('all_forms'),
                        value: 'All'
                    },
                ]
    }
    protected doubleMode: string | null = this.header && this.header.form ? this.header.form : null;

    // ======================= Computed values section
    private get inputInflPrcDisabled(): boolean {
        let flag: boolean = false;
        if (this.header && this.header.form && this.formsData[this.header.form]) {
            // flag = !this.formsData['01-123'].hasInflCoef;
            flag = !this.formsData[this.header.form].hasInflCoef;
        }; 
        if (this.doubleMode === 'All') {
            flag = false;
        }
        if (flag) this.inflPrc = null;
        return flag;
    } // проверка примененимости коэффициента инфляции для текущей формы

    private get srcYear(): string {
        let srcY: string = '';
        if (this.header && this.header.year) {
            srcY = this.header.year.toString();
        }
        return srcY
    } // получение года копирование для заголовка модалки

    private get btnDisabled(): boolean {
        return this.budgetForm.filter((item: any) => item.id >= 0).length === 0 || this.total < 1 || this.isLoad
    }

    // ======================= Methods section
    private created(): void {
    }

    private inputFixed(value: string | null): void {
        if (value) {
            value = value.replace('%', '');
        }
        if (value === null || value === '' || value === '.' || !Number(value)) {
            this.inflPrc = null;
            return;
        };
        this.inflPrc = parseFloat(parseFloat(value).toFixed(1)) + '%';
    } // форматирование процента инфляции при блюре инпута

    private async checkTotalData()  {
        await this.ifDataInTotal(this.header, this.doubleMode)
    }

    public get formCodeText(): string {
        if (this.header && this.header.form) {
            return this.fomatedText(this.header.form)
        }
        return ''
    }
}
